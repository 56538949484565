import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import EventModal from '../components/EventModal';
import PageHeader from '../components/PageHeader';

const GuideAdmin = (props) => {
  const { authToken } = useParams();

  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({});

  useEffect(() => {
    fetch(`/api/v1/guides?auth_token=${authToken}`)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [authToken]);

  const guide = data;

  if (guide === undefined) return;

  const events = guide.events || [];

  const onCopyClick = (eventTemplate) => {
    setNewEvent(eventTemplate);
    setIsOpen(true);
  };

  const renderListItem = (event) => {
    const startDate = new Date(event.start_date);
    const endDate = new Date(event.end_date);

    return (
      <tr key={event.id}>
        <td>
          <Form.Control type="date" value={startDate.toISOString().split('T')[0]} />
        </td>
        <td>
          <Form.Control type="time" value={startDate.toLocaleTimeString()} />
        </td>
        <td>
          <Form.Control type="time" value={endDate.toLocaleTimeString()} />
        </td>
        <td>
          <Form.Select>
            <option>Standaard locatie</option>
            <option>Locatie 2</option>
          </Form.Select>
        </td>
        <td>
          <Form.Check label="vol" />
        </td>
        <td>
          <div className="d-flex align-items-center gap-1">
            <button className="btn-icon" onClick={() => onCopyClick(event)}>
              <FontAwesomeIcon icon={icon({ name: 'copy' })} />
            </button>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <PageHeader imageClass="admin" title={`Admin - ${guide.practice}`} />
      <Container>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Starttijd</th>
              <th>Eindtijd</th>
              <th>Locatie</th>
              <th>Volgeboekt</th>
              <th>Acties</th>
            </tr>
          </thead>
          <tbody>{events.map(renderListItem)}</tbody>
        </Table>
      </Container>
      {isOpen && <EventModal event={newEvent} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default GuideAdmin;
